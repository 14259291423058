// components/ProductDisplay.tsx
import { Box, Text, Image, Button } from "@chakra-ui/react";
import logo from "../assets/logo/w_logo_white.png";
import mockup_info from "../assets/images/mockup_info.png";

const ProductDisplay = () => (
  <Box
    id="products"
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    py={10}
    bg="linear-gradient(#000000, #5D2CA8, #000000)"
  >
    <Text fontSize="4xl" fontWeight="bold">
      Termékeink
    </Text>
    <Image w={150} src={logo} mt={12} />

    <Text mt={4} fontSize="lg" textAlign="center" w={{ base: 300, md: 500 }}>
      Wobble több mint egy időpontfoglaló. Az admin felület biztosítja a
      szolgáltatások és időpontok kezelését, a foglalási felület, mint egy
      honlap gondoskodik az informatív megjelenésről, referenciák és blog poszt
      megjelenítéséről, valamint a gondtalan időpontfoglalásról.
    </Text>
    <Image
      w={{ base: 350, sm: 550, md: 850 }}
      src={mockup_info}
      alt="Wobble product"
    />
    <Button
      mt={8}
      size="lg"
      colorScheme="purple"
      as="a"
      href="https://wobble.hu"
      target="_blank"
      rel="noopener noreferrer"
    >
      Megnézem
    </Button>
  </Box>
);

export default ProductDisplay;
