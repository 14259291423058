import { Box, SimpleGrid, Text, Icon, VStack, Center } from "@chakra-ui/react";
import { FaCloud, FaCompass, FaDollarSign } from "react-icons/fa";

const features = [
  {
    icon: FaDollarSign,
    title: "Költséghatékonyság",
    description:
      "A SaaS megoldások általában előfizetéses rendszerben működnek, ami lehetővé teszi a vállalkozások számára, hogy csökkentsék az IT költségeiket. Nincs szükség drága licencekre, hardverekre vagy folyamatos karbantartásra.",
  },
  {
    icon: FaCloud,
    title: "Rugalmasság és skálázhatóság",
    description:
      "A SaaS szolgáltatások könnyen bővíthetők vagy csökkenthetők, ami ideálissá teszi őket a növekvő vagy változó igényekhez való alkalmazkodásra. A felhasználók gyorsan hozzáférhetnek új funkciókhoz, frissítésekhez és szolgáltatásokhoz anélkül, hogy új telepítési folyamatokra lenne szükség.",
  },
  {
    icon: FaCompass,
    title: "Könnyű hozzáférés és együttműködés",
    description:
      "Mivel a SaaS megoldások böngészőn keresztül érhetők el, a felhasználók bárhonnan hozzáférhetnek a szoftverhez, amennyiben van internetkapcsolatuk. Ez megkönnyíti a távoli munkavégzést és az együttműködést, hiszen több felhasználó is valós időben dolgozhat ugyanazon a projekten.",
  },
];

const Features = () => (
  <Box
    textAlign="center"
    py={10}
    id="features"
    maxW="1400px"
    mx="auto"
    px={{ base: 4, md: 8 }}
  >
    <Text fontSize="3xl" fontWeight="bold">
      Segítünk könnyebbé tenni a mindennapokat
    </Text>
    <Center mt={12}>
      <Box w={{ base: 300, md: 500 }}>
        <Text>
          Mi, a Miller Productionsnél ismerjük a kihívásokat, amelyekkel Ön
          szembesül nap mint nap. Tudjuk, milyen fontos az idő, az erőforrások
          hatékony kihasználása, és hogy mindezt a lehető legjobb megjelenéssel
          párosítsa. Ezért hoztunk létre egy rendszert, amelyet kifejezetten az
          Ön igényeire szabtunk.
        </Text>
        <Text w={{ base: 300, md: 500 }} mt={4}>
          A SaaS, vagyis szoftver mint szolgáltatás, olyan modern megoldás,
          amely lehetővé teszi a felhasználók számára, hogy interneten keresztül
          hozzáférjenek a szoftverekhez anélkül, hogy azokat telepíteniük vagy
          karbantartaniuk kellene. Ez a modell számos előnnyel jár, amelyek a
          vállalkozások számára különösen vonzóak.
        </Text>
      </Box>
    </Center>
    <Center>
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        spacing={8}
        mt={8}
        justifyContent="center"
        px={{ base: 4, md: 0 }}
      >
        {features.map((feature, index) => (
          <VStack
            key={index}
            p={5}
            border="1px"
            borderRadius="md"
            borderColor="gray.500"
            bg="black"
          >
            <Icon as={feature.icon} w={10} h={10} color="white" />
            <Text fontSize="xl" fontWeight="bold">
              {feature.title}
            </Text>
            <Text>{feature.description}</Text>
          </VStack>
        ))}
      </SimpleGrid>
    </Center>
  </Box>
);

export default Features;
